export enum SinglePlanWidgetRole {
  Image = 'imageX1',
  ImageContainer = 'imageContainer',
  PlanName = 'planName',
  RibbonWidget = 'ribbon1',
  RibbonContainer = 'ribbonContainer',
  RibbonText = 'ribbonText',
  PricingWidget = 'currencyPosition1',
  Price = 'price',
  Frequency = 'frequency',
  Currency = 'currency',
  Description = 'planDescription',
  PlanDuration = 'planDuration',
  Button = 'ctaButton',
  ContentDividerHorizontal = 'horizontalContentDivider',
  ContentDividerVertical = 'contentDivider',
  ContentDividerContainer = 'contentDividerContainer',
  PerksWidget = 'benefits1',
  PerksContainer = 'perksContainer',
  PerkItem = 'perk',
  PerkContent = 'perkContent',
  PerkText = 'perkText',
  PerkIcon = 'perkIcon',
  PerkDivider = 'perkDivider',
  MultiStateBox = 'multiStateBox1',
  PlanWidget = 'singlePlan1',
  PlanInnerContainer = 'box2',
  PlanContainer = 'singlePlanContainer',
  StateLoaded = 'planInfo',
  StateSpinner = 'loading',
  StateEmpty = 'empty',
}

export enum PlanListWidgetRole {
  PlanListWidget = 'planListWidget',
  PlanListStates = 'planListStates',
  LoadingState = 'loading',
  PlansInfoState = 'plansInfo',
  ErrorState = 'error',
  EmptyState = 'TODO',
  Header = 'header',
  Title = 'title',
  TitleContainer = 'titleContainer',
  Subtitle = 'subtitle',
  SubtitleContainer = 'subtitleContainer',
  Disclaimer = 'disclaimer',
  DisclaimerContainer = 'disclaimerContainer',
  PlanList = 'planList',
  PlanListItem = 'planListItem',
  PlanWidget = 'plan1',
}
